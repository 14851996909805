html,
body {
	padding: 0;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
		Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
	color: inherit;
	text-decoration: none;
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

a.no-blue:hover {
	color: initial;
}

/** top sections base  **/
.section-top {
	width: 90%;
	margin: 0 auto;
	display: flex;
	flex-direction: row;
	justify-content: left;
	padding-top: 90px;
}
.section-top .MuiSvgIcon-root {
	font-size: 40px;
	cursor: pointer;
}
.section-top h1 {
	margin-left: 10px;
	margin-bottom: 0;
	font-size: 30px;
	font-weight: 600;
}

@media (min-width: 1050px) {
	.section-top {
		padding-top: 250px;
		width: 80%;
	}
	.section-top .MuiSvgIcon-root {
		display: none;
	}
	.section-top h1 {
		padding: 0;
		font-size: 32px;
		margin: 0;
		margin-bottom: 30px;
	}
}

/******/
