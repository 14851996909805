/********Navbar*********/

.Navbar {
	width: 100%;
	position: fixed;
	height: 50px;
	top: 0;
	z-index: 100;
	background: rgba(0, 0, 0, 0.7);
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.Navbar_logo-container {
	flex: 0 1 100px;
	height: 100%;
	padding: 10px 0px 10px 10px;
}

.Navbar_logo {
	width: 100%;
	height: 100%;
}

.Navbar-actions {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	flex: 0 1 calc(20%);
}

.Navbar-actions-icon {
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50px;
	color: white;
}

.Navbar-actions-icon.selected {
	background-color: orange;
}

/** desktop navbar **/

.desktop-navbar {
	width: 100%;
	position: fixed;
	top: 0;
	z-index: 100;
	background: rgba(0, 0, 0, 0.75);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 0 15px;
}

.desktop-navbar .row-navbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
	padding: 30px 0;
	width: 80%;
	margin: 0px auto;
	max-width: 1200px;
}

.desktop-navbar .row-navbar:first-of-type {
	padding-bottom: 0;
}

.desktop-navbar .logo-container {
	flex: 0 1 100px;
	height: 50px;
	padding: 0px;
}

.desktop-navbar .logo-container img {
	height: 100%;
}

.desktop-navbar .row-navbar .nav-item {
	color: white;
	padding: 8px 24px;
	border-radius: 8px;
	color: white;
	font-size: 1.1rem;
	background-color: transparent;
}

.desktop-navbar .row-navbar .nav-item a:hover {
	color: white;
}

.desktop-navbar .row-navbar .nav-item.active {
	background-color: rgba(255, 255, 255, 0.15);
	position: relative;
}
.desktop-navbar .row-navbar .nav-item.active::after {
	content: "";
	position: absolute;
	width: 50%;
	left: 25%;
	border-bottom: 5px solid #ff960f;
	bottom: -12px;
}

.desktop-navbar .row-navbar .nav-item:first-of-type,
.desktop-navbar .row-navbar .nav-item:first-of-type.active {
	background-color: #ff960f;
}

.desktop-navbar .row-navbar .nav-item:last-of-type,
.desktop-navbar .row-navbar .nav-item:last-of-type.active {
	background-color: #1db954;
}

.desktop-navbar .nav-desktop-social-media {
	display: flex;
}

.desktop-navbar .nav-desktop-social-media .social-media-icon {
	margin-left: 8px;
}

.desktop-navbar .icon {
	color: white;
	cursor: pointer;
}


.desktop-navbar .chevron-icon {
	color: #ff960f;
	font-size: 2rem;
}

.desktop-navbar .nav-desktop-home {
	padding: 0 24px;
	width: fit-content;
	position: relative;
}

.desktop-navbar .nav-desktop-home a::after,
.desktop-navbar .nav-desktop-home a::before {
	content: "";
	height: 22px;
	border-left: 1px solid white;
	position: absolute;
	left: 0px;
	top: 2px;
}

.desktop-navbar .nav-desktop-home a::before {
	left: unset;
	right: 0;
}

.desktop-navbar .nav-desktop-lang {
	display: flex;
	align-items: center;
	padding: 0 24px;
}

.desktop-navbar .nav-desktop-lang span {
	color: white;
	padding: 0 3px 0 13px;
}

.desktop-navbar .nav-desktop-right {
	display: flex;
	align-items: center;
}

.desktop-navbar .nav-desktop-search-input {
	padding: 6px 38px 6px 16px;
	border-radius: 12px;
	border: 0;
	width: 300px;
	font-size: 1.1rem;
}

.desktop-navbar .nav-desktop-search-input:focus {
	outline: none;
}

.desktop-search {
	position: relative;
	padding: 0 24px;
}

.desktop-search .icon {
	color: #333;
	position: absolute;
	right: 32px;
	top: 4px;
	font-size: 28px;
}

.desktop-navbar .whatsapp-desktop {
	cursor: pointer;
	position: fixed;
	bottom: 16px;
	right: 16px;
}

.desktop-navbar .whatsapp-desktop img {
	width: 60px;
}

@media (max-width: 1300px) {
	.desktop-navbar .row-navbar {
		max-width: 100%;
		width: 100%;
	}
}

.desktop-navbar {
	display: none;
}

@media (min-width: 1050px) {
	.carousel-button-actions {
		display: none;
	}
	.desktop-navbar {
		display: block;
	}
	.Navbar {
		display: none;
	}
}

/**************Language Toolbar******************/

.language-toolbar {
	width: 100%;
	position: fixed;
	top: 50px;
	z-index: 1000;
	padding-left: 0;
	box-shadow: 0px 1px 6px 0px rgb(0 0 0 / 40%);
	margin-bottom: 0;
	border-radius: 0 0 20px 20px;
}

.language-toolbar li {
	background-color: white;
	padding: 6px 10px;
	font-weight: 400;
	color: #9fa2a4;
}

.language-toolbar li img {
	width: 25px;
	height: 25px;
	margin-right: 10px;
	margin-left: 12px;
	border-radius: 50px;
}

.language-toolbar li:last-of-type {
	padding-bottom: 16px;
	border-radius: 0 0 20px 20px;
}

li.language-toolbar-title {
	background-color: #f0f0f0;
	color: black;
	font-weight: 700;
	padding-left: 20px;
}

/**************Search Toolbar***************/

.navbar-search-form {
	width: 100%;
	position: fixed;
	display: block;
	top: 50px;
	z-index: 1000;
	background-color: white;
	padding: 0;
	box-shadow: 0px 1px 6px 0px rgb(0 0 0 / 40%);
}

.navbar-search-form form {
	position: relative;
	width: 100%;
}

.navbar-search-form input {
	width: 100%;
	padding: 8px;
	padding-left: 36px;
	border: 1px solid gray;
}

.navbar-search-form .MuiSvgIcon-root {
	position: absolute;
	color: gray;
	left: 10px;
	top: 8px;
	cursor: pointer;
}

.search-results {
	z-index: 1;
	width: 100%;
	list-style: none;
	padding: 0;
	position: relative;
}

.navbar-search-form .MuiSvgIcon-root.close, .MuiSvgIcon-root.close {
	position: absolute;
    top: 8px;
    right: 8px;
	left: unset;
    cursor: pointer;
	color: gray;
}

.search-results li {
	padding: 10px;
	padding-left: 30px;
	border-bottom: 1px solid #f0f0f0;
	background-color: white;
	cursor: pointer;
}

.search-results li.title {
	color: #333;
	font-weight: 700;
	padding: 10px;
	padding-left: 20px;
	background-color: #f0f0f0;
}

.search-results li:last-of-type {
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
}

.no-result {
	height: 100px;
	padding-top: 35px;
	text-align: center;
}


@media (min-width: 1050px) {

	.search-results {
		position: absolute;
		background-color: white;
		min-width: 650px;
	}
}


/*************Menu mobile*************/

.navbar-menu {
	width: 70%;
	right: 0;
	top: 50px;
	background-color: white;
	position: fixed;
	border-bottom-left-radius: 20px;
	border-top-left-radius: 20px;
	z-index: 10000;
	box-shadow: 0px 1px 6px 0px rgb(0 0 0 / 40%);
}

.menu-options-top {
	display: flex;
	justify-content: space-between;
	cursor: pointer;
}

.menu-options-top.submenu {
	padding: 8px 0;
}

.menu-options,
.menu-options ul {
	list-style: none;
	padding: 8px 16px;
	margin: 0;
}

.menu-options-item {
	padding: 10px 0px;
	font-weight: bold;
	border-bottom: 1px solid gray;
	width: 100%;
}

.menu-options-item a {
	display: block;
}
.menu-options-item a:hover {
	color: unset;
}

.menu-options-item a.active {
	color: #00000080;
}

.menu-options-item .MuiSvgIcon-root {
	color: orange;
}

.menu-options-item:last-of-type {
	border-bottom: none;
}

.menu-li-item {
	color: #717171;
	font-weight: 500;
	border-top: 1px solid #f3f3f3;
}

.menu-li-item li {
	padding: 8px 0;
}

/**************************Slider*****************************/

.carousel.slide {
	background: #f0f0f0;
}

.slider-home.carousel.slide.carousel-fade,
.slider-home.carousel.slide.carousel-fade,
.slider-home .carousel-item {
	height: 100vh;
	border-bottom-left-radius: 30px;
	border-bottom-right-radius: 30px;
}

.slider-home .carousel-caption {
	height: 100%;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6));
	left: 0%;
	width: 100%;
	padding-bottom: 0;
}

.slider-home .carousel-caption .caption {
	height: 85%;
}

.carousel-content {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 70%;
	margin: 0 auto;
}

.carousel-content h3 {
	font-weight: bold;
	font-size: 32px;
	margin-bottom: 30px;
}

.carousel-content p {
	font-weight: 300;
	font-size: 18px;
	line-height: 1.2;
	margin-bottom: 40px;
}

.carousel-content a {
	border: 1px solid white;
	border-radius: 10px;
	padding: 8px;
	min-width: 200px;
	font-size: 1.1rem;
}

.carousel-content a:hover {
	color: whitesmoke;
}

.carousel-button-actions {
	z-index: 10;
	position: absolute;
	bottom: 70px;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	width: 100%;
}

.carousel-button-actions a {
	flex-basis: calc(50% - 1rem);
	padding: 8px;
	border-radius: 30px;
	border: none;
	color: white;
	text-align: center;
	font-size: 1.1rem;
}

.carousel-button-actions a::hover {
	color: unset;
}

.carousel-button-actions a:first-of-type {
	background-color: #ff960f;
}

.carousel-button-actions a:last-of-type {
	background-color: #1db954;
}

.carousel-img {
	object-fit: cover;
	height: 100%;
}

.carousel-inner {
	margin-bottom: -40px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
	margin-bottom: 90px;
	height: 40px;
	width: 40px;
}

.slider-product.individual .carousel-inner {
	margin-bottom: 20px;
}

.slider-product.individual .carousel-control-prev-icon,
.slider-product.individual .carousel-control-next-icon {
	margin-bottom: 0;
}

.carousel-indicators {
	display: none;
}

@media (min-width: 1050px) {
	.slider-home.carousel.slide.carousel-fade,
	.slider-home.carousel.slide.carousel-fade,
	.slider-home .carousel-item {
		height: 900px;
	}
	.slider-home {
		height: 800px;
	}
	.carousel-content {
		width: 50%;
	}
	.carousel-content h3 {
		font-size: 45px;
	}
	.carousel-content p {
		font-size: 24px;
		font-weight: 300;
		margin-bottom: 40px;
	}
	.carousel-control-prev-icon,
	.carousel-control-next-icon {
		height: 60px;
		width: 60px;
		margin-bottom: 0;
	}
	.carousel-button-actions {
		display: none;
	}

	.carousel-indicators {
		display: flex;
		bottom: 40px;
	}

	.carousel-indicators [data-bs-target] {
		height: 16px;
		width: 16px;
		border-radius: 50%;
		margin: 0 6px;
	}

	.slider-home .carousel-caption .caption {
		height: 100%;
	}
}

/****************Bottom Bar*****************/

.bottom-bar {
	width: 100%;
	position: fixed;
	bottom: 0;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: flex-end;
	background-color: #f0f0f0;
	padding: 2px 8px;
	border-top-right-radius: 20px;
	border-top-left-radius: 20px;
	box-shadow: 0px -2px 8px 2px rgba(0 0 0 0.4);
	z-index: 10;
}

.bottom-bar svg.svg-inline--fa.fa-home.fa-w-18 {
	font-size: 20px;
}

.bottom-bar .MuiSvgIcon-root {
	color: #ff960f;
	height: 1.3em;
	width: 1.3em;
}

.bottom-bar .icon-set {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.bottom-bar .icon-set span {
	color: orange;
	font-size: 10px;
	margin-top: 3px;
}

.bottom-bar .icon-set .icon {
	width: 32px;
	height: 22px;
	background-size: cover;
}

@media (min-width: 1050px) {
	.bottom-bar {
		display: none;
	}
}

/****************Products section*****************/

.products-section {
	position: relative;
	width: 100%;
	background-color: #f0f0f0;
	padding-bottom: 60px;
}

.products-section p {
	width: 85%;
	margin: 16px auto 30px;
	font-weight: 600;
	font-size: 1.2em;
}

.products-section .products-desktop {
	display: none;
}

.products-options {
	width: 90%;
	margin: 0 auto;
}

.products-section.home .section-top .MuiSvgIcon-root {
	display: none;
}

@media (min-width: 1050px) {
	.products-options {
		display: none;
	}

	.products-section p {
		text-align: center;
		margin: 10px 0 40px;
		width: 100%;
		font-size: 24px;
	}

	.section-top.products h1 {
		font-size: 32px;
		font-weight: 700;
		width: 90%;
		margin: 0 auto;
		text-align: center;
	}
	.products-section.home .section-top {
		padding-top: 80px;
	}

	/***/

	.products-section .products-desktop {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		width: 80%;
		margin: 0 auto;
	}

	.products-section .category-item {
		display: flex;
		width: 40%;
		box-sizing: border-box;
		background-color: white;
		border-radius: 20px;
		box-shadow: 2px 2px 6px 0px rgb(0 0 0 / 40%);
		margin: 20px;
	}

	.products-section .category-item .category-img {
		background-position: center center;
		width: 50%;
		height: 100%;
		border-radius: 20px;
		background-repeat: no-repeat;
		background-size: contain;
		background-color: white;
	}

	.products-section .category-item .text-wrapper {
		width: 50%;
		padding: 30px 20px;
	}

	.products-section .category-item .text-wrapper h3 {
		font-size: 24px;
	}

	.products-section .category-item .text-wrapper p {
		font-size: 14px;
		text-align: left;
		font-weight: 400;
		line-height: 1.2;
		margin-bottom: 30px;
	}

	.products-section .category-item .text-wrapper button {
		border: 2px solid grey;
		padding: 4px 8px;
		background-color: transparent;
		border-radius: 6px;
	}
	/***/
}

@media (max-width: 1300px) {
	.products-section,
	.products-section .products-desktop {
		width: 100%;
	}
}

/************ Accordion  ******************/

.accordion-products {
	width: 80%;
	margin: 0 auto;
}

.accordion-wrapper {
	padding: 0;
	box-shadow: 0px 2px 8px -2px rgb(0 0 0 / 40%);
	border-radius: 16px;
	margin: 0 0 16px;
}

.accordion-body {
	padding: 0;
	padding-right: 20px;
}
.accordion-item .accordion-button {
	border: 0;
	box-shadow: none;
	border-radius: 0;
	justify-content: space-between;
	padding-right: 0;
}

.accordion-button:not(.collapsed) {
	color: unset;
	background-color: transparent;
}

.accordion-item:first-of-type {
	background-color: white;
	border-radius: 8px;
}

.accordion-item:first-of-type .accordion-button.collapsed {
	background-color: white;
	border-radius: 8px;
}
.accordion-item:last-of-type .accordion-button.collapsed {
	background-color: white;
	border-radius: 8px;
}

.accordion-item {
	border: 0;
}

.accordion-header.subcategory .accordion-button,
.accordion-header.subcategory .accordion-button.collapsed {
	border-radius: 0;
	border-top: 1px solid #00000020;
	margin-left: 20px;
	width: calc(100% - 20px);
	font-size: 18px;
	font-weight: 600;
	color: #9a9a9a;
}

.accordion-header.category .accordion-button,
.accordion-header.category .accordion-button.collapsed {
	font-size: 18px;
	font-weight: 600;
	padding-right: 8px;
}

.accordion-body .item-product {
	font-size: 18px;
	color: #9a9a9a;
	font-weight: 600;
	margin-left: 20px;
	border-top: 1px solid #00000020;
	padding: 10px 0;
	padding-left: 4px;
	cursor: pointer;
}


.accordion-body .item-product {
	display: block;
}

.accordion-body .item-product.selected {
	background-color: #ff960f30;
	display: block;
}

.accordion-body .item-product.subcat {
	margin-left: 60px;
}

.accordion-button::after {
	display: none;
}

.accordion-button .MuiSvgIcon-root {
	color: #ff960f;
	font-size: 30px;
}
.accordion-button:not(.collapsed) .MuiSvgIcon-root {
	transform: rotate(-180deg);
}
/*********************Group Item Product************************/

.products-group-item {
	background-color: white;
	padding: 10px 20px;
	margin-bottom: 16px;
	/* box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.45); */
	box-shadow: 0px 2px 8px 2px rgb(0 0 0 / 40%);
	border-radius: 4px;
}

.products-group-item-title {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	font-weight: 600;
	padding-bottom: 12px;
}

/*********************Category Item Product************************/

.products-category-item:first-of-type {
	border-top: 1px solid #bdbdbd;
}

.products-category-item {
	color: #bdbdbd;
	list-style: none;
	padding: 10px 0px;
	border-bottom: 1px solid #bdbdbd;
}

.products-category-item:last-of-type {
	border-bottom: none;
}

/*********************Contact Form************************/

.contact-form {
	position: relative;
	width: 90%;
	margin: 0 auto 80px;
	z-index: 40;
	background-color: white;
}


.contact-form.home {
	margin-top: 40px;
}


.contact-form form {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: relative;
}

.contact-form form .loader {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(255,255,255,.6);
	z-index: 10;
	display: none;
	justify-content: center;
	align-items: center;
}


.contact-form form .loader .MuiSvgIcon-root {
	color: orange;
	font-size: 40px;
	opacity: 80%;
}

.rotate {
	animation: rotate .8s linear infinite;
}
@keyframes rotate {
	to {
		transform: rotate(360deg);
	}
}

.contact-form form .loader.visible {
	display: flex;
}

.contact-form form .column {
	display: flex;
	flex-direction: column;
}

.contact-form form input,
.contact-form form textarea {
	padding: 8px 10px;
	border: 1px solid hsl(0, 0%, 80%);
	border-radius: 4px;
	outline: none;
	background-color: white;
	color: #95989a;
}

.contact-form form input {
	margin-bottom: 18px;
}

.contact-form-location {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.contact-form form button {
	border: none;
	border-radius: 40px;
	outline: none;
	background-color: #1db954;
	padding: 10px;
	color: white;
	width: 100%;
	margin: 20px auto;
	font-size: 1.1rem;
}

.contact-form-bottom {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding-top: 12px;
}

.contact-form-location {
	width: 100%;
}

.contact-form-location .select-input {
	min-width: 48%;
} 

.select-input {
	margin-bottom: 18px;
}

/**/

.contact-form .check .MuiSvgIcon-root {
	color: #2379f4;
	cursor: pointer;
	font-size: 24px;
	margin: 0 8px;
}

.contact-form .check span {
	color: grey;
	position: relative;
	top: 2px;
}


.section-top.form {
	width: 100%;
	padding-bottom: 40px;
}

.section-top.form .MuiSvgIcon-root {
	display: none;
}

@media (min-width: 960px) {
	.contact-form form {
		flex-direction: row;
		align-items: flex-start;
		max-width: 900px;
		margin: 0 auto;
	}

	.contact-form form .column {
		width: 48%;
		height: 300px;
	}

	.section-top.form h1 {
		width: 100%;
		font-size: 36px;
		font-weight: 700;
		text-align: center;
		margin-bottom: 16px;
	}

	.contact-form form textarea {
		flex: 1 1 auto;
	}

	.contact-form form .contact-form-button-cont {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.contact-form form button {
		padding: 6px 16px;
		width: auto;
		min-width: 175px;
	}

	.contact-form {
		width: 75%;
	}

	.contact-form.home .section-top {
		padding-top: 80px;
	}

	.contact-form-bottom {
		justify-content: space-between;
		padding-top: 0;
	}
}

/*********************Footer************************/

footer {
	position: relative;
	bottom: 0;
	background-color: #4e4d5a;
}

.footer-content {
	padding: 75px 0 90px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	text-align: center;
	width: 80%;
	margin: 0 auto;
}

.footer-content-desc {
	font-weight: 600;
	font-size: 20px;
	color: white;
	margin-bottom: 30px;
}

.footer-content-location,
.footer-content-schedule,
.footer-content-newsletter {
	margin-bottom: 30px;
}

.footer-content-location h3,
.footer-content-schedule h3,
.footer-content-newsletter h3 {
	font-weight: 600;
	font-size: 16px;
	color: white;
	margin-bottom: 0;
}

.footer-content-location p,
.footer-content-schedule p,
.footer-content-newsletter p {
	margin-bottom: 0;
}

.footer-logo-container {
	margin-bottom: 10px;
	width: auto;
}

.footer-content p {
	color: #c6ced4;
}

.footer-social-media {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 20px auto 30px;
	justify-content: space-between;
}

.footer-social-media h3 {
	font-weight: 600;
	font-size: 20px;
	color: white;
	margin-right: 10px;
	margin-bottom: 0;
}

.footer-social-media .redes-icons {
	display: flex;
	flex-direction: row;
}

.social-media-icon {
	background-color: #6a6975;
	padding: 7px 8px;
	border-radius: 40px;
	font-size: 20px;
	color: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 5px;
}

footer .newsletter-button {
	color: white;
	background-color: #1db954;
	border: none;
	padding: 10px 30px;
	border-radius: 30px;
	margin: 8px auto 0;
	display: block;
	font-size: 1.1rem;
}

.footer-block {
	padding: 0 30px;
}

@media (min-width: 1100px) {
	.footer-content {
		flex-direction: row;
		align-items: flex-start;
		text-align: left;
		padding: 120px 0;
		justify-content: center;
		max-width: 1240px;
	}

	.footer-block {
		padding: 0 20px;
		max-width: 400px;
		min-width: 250px;
	}

	footer .newsletter-button {
		width: 100%;
		text-align: center;
	}

	.footer-social-media {
		flex-direction: column;
		align-items: flex-start;
	}

	.footer-social-media .redes-icons {
		margin-top: 10px;
	}

	.footer-logo-container {
		width: 450px;
	}
}

@media (max-width: 1300px) {
	.footer-content {
		width: 100%;
	}
}

/*********************Map************************/

iframe.map-iframe {
	width: 100%;
	height: 70vh;
	border-radius: 30px;
	position: relative;
	z-index: 1;
}

.map-container {
	position: relative;
	top: 40px;
}

/*********************Products List************************/

.products-list-page {
	width: 90%;
	margin: 70px auto 60px;
}

.products-list {
	display: flex;
	flex-direction: column;
}

.products-list-item {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	margin-bottom: 20px;
	background: white;
	border-radius: 10px;
	padding: 20px;
	box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 40%);
}

.products-list-item-img {
	width: 100%;
	height: 100%;
}

.products-list-item h3 {
	font-weight: 600;
	font-size: 20px;
	height: 50px;
	text-overflow: ellipsis;
	overflow: hidden;
	display: -webkit-box !important;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	white-space: normal;
	margin-bottom: 16px;
}

.products-list-item p {
	margin: 0;
	font-weight: bold;
	font-size: 18px;
}

.products-list-item span {
	background: #95989a;
	color: white;
	font-weight: bold;
	padding: 3px 8px;
	border-radius: 6px;
	font-size: 10px;
	margin-bottom: 20px;
	width: fit-content;
}

.products-list-item small {
	color: #949494;
	margin-bottom: 10px;
}

.products-list-item button {
	border: 1px solid #1db954;
	color: #1db954;
	padding: 5px 10px;
	background-color: white;
	font-weight: 700;
	border-radius: 20px;
	width: 100%;
}

.products-list-item .image {
	width: 40%;
	object-fit: cover;
	border-radius: 15px;
	background-size: cover;
	margin: 0;
}

.products-list .text-wrapper {
	width: 60%;
	padding-left: 30px;
}


.products-list .text-wrapper .item-price {
	display: flex;
	flex-direction: column;
}

.pagination-products-list {
	padding: 0 0 60px;
}

.subcategory-page .aside-options {
	display: none;
}

.subcategory-page {
	display: flex;
	background-color: #f0f0f0;
}

.subcategory-page h2.subcategory {
	font-size: 28px;
	font-weight: 700;
	padding: 5px 0 35px;
}

@media (min-width: 1050px) {
	.products-list .text-wrapper {
		width: 100%;
		margin: 0;
		padding: 0 4px;
	}

	.products-list .text-wrapper .item-price {
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 16px;
	}

	.products-list-item .image {
		width: 100%;
		height: 160px;
		border-radius: 15px;
		margin-bottom: 16px;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: contain;
		background-color: white;
	}

	.subcategory-page {
		background-color: white;
		width: 80%;
		max-width: 1300px;
		margin: 0 auto;
		margin-top: 250px;
		justify-content: space-between;
	}
	.subcategory-page .aside-options {
		display: block;
		width: 440px;
		padding-right: 40px;
	}

	.subcategory-page .aside-options .products-options {
		display: block;
	}

	.products-list-page {
		margin-top: 0;
	}

	.products-list-page .products-list {
		width: 100%;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.products-list-page .products-list .products-list-item {
		width: 280px;
		margin: 0 30px 30px 0;
		flex-direction: column;
	}
}

@media (max-width: 1300px) {
	.subcategory-page {
		width: 100%;
	}
}

/********************* breadcrumb ******************************/

.breadcrumbs {
	text-transform: uppercase;
	font-weight: 600;
	font-size: 15px;
}

.breadcrumbs span::after {
	content: " | ";
}
.breadcrumbs span:last-of-type::after {
	display: none;
}
.breadcrumbs .active {
	color: #888;
}

.breadcrumbs .home::after {
	display: none;
}

.breadcrumbs .home .MuiSvgIcon-root {
	font-size: 30px;
	position: relative;
	bottom: 1px;
}

@media (min-width: 1050px) {
	.bread-crumbs {
		font-size: 16px;
	}
	.breadcrumbs .home {
		display: none;
	}
}

/*********************Frequent Questions************************/

.frequent-questions-list {
	list-style: none;
	padding: 0;
	width: 80%;
	margin: 20px auto 60px;
}

.accordion-header.faq .accordion-button,
.accordion-header.faq .accordion-button.collapsed {
	color: #666;
	font-weight: bold;
	line-height: 1.3;
	font-size: 1.2rem;
	border-top: 1px solid #00000020;
}

.faq .accordion-item:first-of-type .accordion-button.collapsed,
.faq .accordion-item:last-of-type .accordion-button.collapsed {
	border-radius: 0;
}

.faq .accordion-item .accordion-button,
.faq .accordion-item .accordion-button.collapsed {
	padding: 22px 0;
}

.faq-answer {
	padding-bottom: 24px;
	color: #8f8f8f;
	font-weight: 400;
}

@media (min-width: 1050px) {
}

/*********************Product Screen************************/

.product-screen {
	margin: 70px 0 0px;
}

.product-screen h2.title-product,
.product-screen .breadcrumbs {
	width: 85%;
	margin: 0 auto;
}

.product-screen h2.title-product {
	margin-bottom: 20px;
}

.product-screen .video {
	background-color: #2379f4;
	border-radius: 0 0 20px 20px;
	padding: 30px 0 70px;
	margin-top: -30px;
	box-shadow: 0px 2px 8px 2px rgb(0 0 0 / 40%);
}

.product-screen .row {
	display: flex;
	flex-direction: column;
}

.short-desc-product h2 {
	padding-top: 20px;
}

.price-short-desc-product {
	width: 85%;
	margin: 0 auto;
}

.price-actions-product {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	margin-top: 20px;
}

.price-product p {
	font-weight: bold;
	font-size: 20px;
	margin-bottom: 0;
}

.price-product small {
	color: #949494;
	font-size: 15px;
}

.product-actions {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	position: relative;
}

.product-actions span {
	background: #95989a;
	color: white;
	padding: 5px 10px;
	border-radius: 10px;
	margin-right: 10px;
}

.social-media-container .share-actions-icon {
	padding: 5px 10px;
	border-radius: 50px;
	cursor: pointer;
}

.social-media-container .social-media-icons {
	position: absolute;
	background-color: #f0f0f0;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	right: 10px;
	top: 40px;
	padding: 10px;
	width: auto;
	box-shadow: 2px 3px 6px 0px rgb(0 0 0 / 30%);
}

.social-media-container .social-media-icons:after {
	content: "";
	position: absolute;
	border-style: solid;
	border-width: 0 6px 6px;
	border-color: #191464 transparent;
	display: block;
	width: 0;
	z-index: 1;
	top: -5px;
	right: 5px;
}

.social-media-container .social-media-icons div {
	background-color: #191464;
	color: white;
	padding: 4px 8px;
	border-radius: 50px;
	margin: 0 8px;
}

@media (min-width: 1050px) {
	.social-media-container .social-media-icons {
		right: unset;
		left: 48px;
		top: -8px;
		border-radius: 10px;
	}

	.social-media-container .social-media-icons::after {
		display: none;
	}
}

/****/

.short-desc-product p {
	color: #949494;
	margin-bottom: 60px;
}

.specifications {
	position: relative;
	z-index: 1;
	background-color: #f0f0f0;
	padding: 30px 0px 60px;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
	box-shadow: 0px 6px 8px -2px rgb(0 0 0 / 40%);
}

.specifications h2 {
	width: 85%;
	margin: 20px auto;
}

.specifications p {
	width: 85%;
	margin: 0 auto;
	color: #959595;
}

.data-sheet {
	top: -30px;
	position: relative;
	padding: 75px 0px;
	background-color: #2379f4;
	color: white;
}

.data-sheet h2 {
	width: 85%;
	margin: 20px auto;
}

.data-sheet-feature {
	width: 90%;
	margin: 0 auto;
}

.data-sheet-feature {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
}

.data-sheet-feature .dashed-line {
	width: 100%;
	border: 1px dashed white;
	position: relative;
	bottom: 3px;
}

.data-sheet-feature p {
	padding: 0 6px 0 0;
	min-width: 100px;
}

.data-sheet-feature p:last-child {
	font-weight: bold;
	padding: 0 0 0 6px;
	text-align: right;
}

.related-products-section h2 {
	text-align: center;
	color: white;
	margin: 30px 0 40px;
}

.related-products-section {
	padding: 20px 10px 30px;
	position: relative;
	background-color: #f0f0f0;
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	box-shadow: 0px 2px 8px 4px rgb(0 0 0 / 40%);
	background-color: #2379f4;
}

button.descargar {
	border: 2px solid white;
	background: transparent;
	border-radius: 40px;
	padding: 10px 20px;
	color: white;
	font-size: 1.1rem;
	width: 85%;
	margin: 16px 7.5% 0;
}

button.descargar .MuiSvgIcon-root {
	margin-right: 10px;
}

button.cotiza-desktop {
	display: none;
}

.product-screen .row {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	width: 100%;
	margin: 0;
}

.product-screen .upper-row .images-block {
	display: flex;
	width: 100%;
}

.product-screen .upper-row .images-block .images-block-aside {
	display: none;
}

.product-screen .upper-row .images-block .image-actual {
	width: 100%;
	height: 45vh;
	background-size: cover;
	background-position: top center;
}

@media (min-width: 1050px) {
	.product-screen {
		margin: 240px 0 0;
	}

	.product-screen .row {
		display: flex;
		flex-direction: row;
		background-color: #2379f4;
		padding: 0;
		padding-bottom: 80px;
		border-radius: 0 0 30px 30px;
		justify-content: center;
	}

	.product-screen .data-sheet {
		top: 50px;
		padding: 0;
		margin-bottom: 0;
		width: 50%;
	}

	.data-sheet-feature p {
		width: auto;
	}

	.product-screen .video {
		margin-top: 50px;
		width: 50%;
		border-radius: 0;
		box-shadow: none;
		padding-bottom: 0;
	}

	.product-screen .slider-product.individual {
		display: none;
	}

	.product-screen .upper-row {
		display: flex;
		flex-direction: row;
		width: 85%;
		margin: 40px auto;
	}

	.product-screen .upper-row .images-block {
		width: 55%;
	}

	.product-screen .upper-row .images-block .images-block-aside {
		display: flex;
		flex-direction: column;
		max-height: 500px;
		overflow-y: auto;
		width: 270px;
	}

	.product-screen .upper-row .images-block .images-block-aside .image {
		width: 120px;
		max-width: 120px;
		height: 70px;
		min-height: 70px;
		background-color: white;
		margin: 0 10px 10px 0;
		border-radius: 4px;
		overflow: hidden;
		border: 2px solid #ccc;
		background-position: center center;
		cursor: pointer;
		background-repeat: no-repeat;
		background-size: contain;
	}

	.product-screen .upper-row .images-block .image-actual {
		width: 900px;
		height: 500px;
		margin: 0 16px;
		background-position: center center;
		background-size: contain;
		background-repeat: no-repeat;
	}

	.price-short-desc-product {
		width: 30%;
		margin: 0 0 0 30px;
	}

	.product-button {
		display: none !important;
	}

	button.cotiza-desktop {
		width: 100%;
		background-color: #1db954;
		color: white;
		border-radius: 40px;
		border: none;
		outline: none;
		padding: 10px 0px;
		display: block;
		font-size: 1.1em;
	}

	.price-actions-product {
		flex-direction: column;
	}
}

/*************** social media component  ***************/
.social-media-container {
	position: relative;
}

/*********************Related Product Item************************/

.related-product-item {
	position: relative;
	display: flex;
	flex-direction: column;
	margin-right: 20px;
	margin-bottom: 20px;
	border-radius: 10px;
	cursor: pointer;
}

.related-product-img-container {
	height: 180px;
	z-index: 1;
}

.related-product-item-img {
	width: 100%;
    background-position: center center;
	border-radius: 10px;
	height: 100%;
	background-repeat: no-repeat;
	background-size: contain;
	background-color: white;
}

.related-product-item-content {
	padding: 20px;
	background-color: #2379f4;
	color: white;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	top: -10px;
	-webkit-box-shadow: -2px 2px 8px 0px rgba(0, 0, 0, 0.4);
	box-shadow: -2px 2px 8px 0px rgb(0 0 0 / 40%);
}

.related-product-item-content p {
	margin-bottom: 0.75rem;
	font-weight: 600;
	height: 50px;
	text-overflow: ellipsis;
	overflow: hidden;
	display: -webkit-box !important;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	white-space: normal;
	margin-bottom: 16px;
}

.related-product-item-content span {
	border: 1px solid white;
	padding: 5px;
	border-radius: 10px;
	font-size: 14px;
}

.product-button-container {
	position: fixed;
	z-index: 1;
	bottom: 20px;
	width: 100%;
}

.product-button {
	width: 85%;
	margin: 0 auto;
	background-color: #1db954;
	color: white;
	border-radius: 40px;
	border: none;
	outline: none;
	padding: 10px 0px;
	display: block;
	font-size: 1.1em;
	box-shadow: -19px 21px 21px -9px rgba(0,0,0,0.54);
	text-align: center;
}

/** Productos destacados **/

.swiper-wrapper {
	box-sizing: border-box;
	padding: 0 50px;
}

.swiper-button-next,
.swiper-button-prev {
	color: white;
	display: none;
}

@media (min-width: 1050px) {
	.swiper-container {
		margin: 0 200px;
		padding: 0 50px;
	}

	.swiper-button-next,
	.swiper-button-prev {
		display: block;
	}

	.swiper-wrapper {
		padding: 0 10px;
	}
}

/*********************Quienes Somos************************/

.quienes-somos-banner {
	height: 100vh;
	position: relative;
}

.quienes-somos-banner-img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.quienes-somos-banner-content {
	position: absolute;
	top: 0;
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	background: linear-gradient(
		0deg,
		rgba(81, 75, 139, 0.8) 36%,
		rgba(81, 75, 139, 0.8) 67%
	);
	padding: 0 30px;
}

.quienes-somos-banner-content h1,
.quienes-somos-banner-content p {
	color: white;
	font-size: 1.2rem;
	line-height: 1.1;
}

.quienes-somos-banner-content h1 {
	font-weight: 600;
	margin-bottom: 30px;
	font-size: 2.2rem;
}

.quienes-somos-banner-content button {
	border: none;
	border-radius: 40px;
	outline: none;
	background-color: #1db954;
	padding: 16px 32px;
	color: white;
	width: 100%;
	margin: 40px auto;
	font-size: 1.2rem;
	line-height: 1.1;
}

.quienes-somos-content {
	background-color: #f0f0f0;
	padding: 20px 0 40px;
	border-radius: 20px;
	margin-top: -20px;
	box-shadow: 0px 2px 8px 2px rgb(0 0 0 / 40%);
}

.quienes-somos-content h2 {
	width: 85%;
	margin: 40px auto 16px;
	font-weight: bold;
	font-size: 24px;
}

.quienes-somos-content p {
	width: 85%;
	margin: 0 auto 20px;
	color: #777777;
}

.quienes-somos-content .subtit {
	display: block;
    font-weight: 600;
    padding: 16px 0 6px;
    font-size: 18px;
	color: #333;
}

.quienes-somos-contact {
	padding: 30px 0;
}

@media (min-width: 1050px) {
	.quienes-somos-content {
		border-radius: 0 0 20px 20px;
		padding: 60px 0;
	}

	.quienes-somos-banner {
		height: 700px;
	}

	.quienes-somos-banner-content button {
		width: auto;
	}

	.quienes-somos-banner-content h1 {
		margin-bottom: 40px;
		padding-top: 150px;
		font-size: 40px;
	}

	.quienes-somos-banner-content p {
		width: 750px;
		font-size: 24px;
	}

	.quienes-somos-content h2,
	.quienes-somos-content p {
		width: 60%;
	}

	.video .reproductor {
		width: 75%;
		margin: 0 auto;
	}

	@media (max-width: 1300px) {
		.quienes-somos-content {
			max-width: 100%;
			width: 100%;
		}

		.quienes-somos-content h2,
		.quienes-somos-content p {
			width: 70%;
		}

		.video .reproductor {
			width: initial;
		}
	}
}

/** Video **/
.video {
	background-color: #2379f4;
	border-radius: 0 0 20px 20px;
	padding: 90px 0 70px;
	margin-top: -20px;
	box-shadow: 0px 2px 8px 2px rgb(0 0 0 / 40%);
}

.video .reproductor {
	position: relative;
	padding-top: 50.25%;
}

.video .reproductor .react-player {
	position: absolute;
	top: 0;
	left: 0;
}

/********************Novedades************************/

.novedades-list {
	width: 80%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
}

.novedades-list-item {
	margin-top: 24px;
	border-bottom: 1px solid #e5e5e5;
	margin-bottom: 10px;
	cursor: pointer;
}

.novedades-list-item img,
.post-screen-content img {
	width: 100%;
	margin-bottom: 20px;
}

.novedades-list-item h2,
.post-screen-content h2 {
	margin-bottom: 12px;
	font-size: 20px;
	font-weight: 700;
	line-height: 1.1;
}

.novedades-list-item .date,
.post-screen-content .date {
	margin-bottom: 12px;
	font-size: 1em;
}

.novedades-list-item p {
	margin-bottom: 20px;
	color: #797979;
}

.novedades-list-item .img {
	width: 100%;
	height: 200px;
	margin-bottom: 15px;
}

.section-top.post {
	padding: 60px 0 30px;
	width: 100%;
}

@media (min-width: 1050px) {
	.section-top.post {
		width: 100%;
		padding: 70px 0 12px;
	}

	.novedades-list-item .img {
		height: 160px;
	}
	.novedades-list {
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
	}
	.novedades-list-item {
		width: 20%;
		margin: 40px 80px;
		margin-left: 0;
	}
}

/******************Post*********************/

.post-screen-content {
	width: 80%;
	margin: 30px auto;
	display: flex;
}

.post-screen-actions {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	align-items: center;
}

.post-screen-actions small {
	color: #707070;
	font-weight: 700;
}

.post-screen-content p {
	margin-bottom: 20px;
	color: #797979;
}

.post-screen-content .post-aside {
	display: none;
}

.post-screen-content .post-aside .novedades-list-item {
	margin: 0;
	display: flex;
	flex-direction: row;
	border-bottom: 0;
}

.desktop-share {
	display: none;
}

.post-screen-content .post-aside .subscribe-form {
	border-radius: 3px;
	width: 100%;
	background-color: #f0f0f0;
	padding: 30px;
	margin: 20px 0;
	display: flex;
	flex-direction: column;
}

.post-screen-content .post-aside .subscribe-form h3 {
	font-size: 22px;
	font-weight: 700;
}

.post-screen-content .post-aside .subscribe-form p {
	font-size: 16px;
	color: unset;
	line-height: 1.1;
}

.post-screen-content .post-aside .subscribe-form .newsletter-button {
	color: white;
	background-color: #1db954;
	border: none;
	padding: 10px 30px;
	border-radius: 16px;
	margin: 8px auto 0;
	display: block;
	font-size: 1.1rem;
	width: 100%;
}

.post-screen-content .post-aside .novedades-list-item {
	width: 100%;
	max-width: 100%;
	cursor: pointer;
}

.post-screen-content .post-aside .novedades-list-item img {
	width: 150px;
	height: auto;
	margin-right: 20px;
}

.post-screen-content .post-aside .novedades-list-item h2 {
	font-size: 16px;
	line-height: 1;
}
.post-screen-content .post-aside .novedades-list-item .date {
	font-size: 14px;
}

.post-screen-content .post-nota {
	width: 90%;
	margin: 0 auto;
	word-break: break-word;
}

@media (min-width: 1050px) {
	.post-screen-content .post-aside {
		display: flex;
		flex-direction: column;
		width: 400px;
		padding-left: 60px;
	}
	.post-screen-content .post-nota {
		width: calc(100% - 400px);
	}
	.post-screen-content {
		margin-top: 160px;
	}

	.novedades-top.post h1,
	.novedades-top.post h2.related-notes {
		text-align: left;
		width: 100%;
		margin-bottom: 20px;
		font-size: 26px;
		font-weight: 700;
	}

	h2.related-notes {
		padding-bottom: 30px;
		margin-bottom: 20px;
		border-bottom: 1px solid #e5e5e5;
	}

	h2.related-notes:first-child {
		margin-top: 75px;
	}

	.post-screen-content h2 {
		font-size: 24px;
		margin-top: 8px;
	}
	.post-screen-actions {
		flex-direction: column-reverse;
		align-items: flex-start;
	}
}

@media (max-width: 1300px) {
	.post-screen-content {
		width: 95%;
	}
}

/*****************Featured Products********************/

.featured-products {
	background-color: #2379f4;
	position: relative;
	top: -20px;
	padding: 10px 0px 40px;
}

.featured-products h2 {
	color: white;
	text-align: center;
	padding: 50px 0 70px;
}

/*********************Pagination************************/

.pagination-component {
	margin: 20px auto;
	width: 90%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.pagination-component button {
	border: none;
	background-color: transparent;
	color: #4e4d5a;
	font-weight: 600;
	font-size: 16px;
}

.pagination-component button.selected {
	color: orange;
}

.pagination-component .MuiSvgIcon-root {
	color: orange;
}

.contact-page {
	padding-top: 60px;
}

.pointer {
	cursor: pointer;
}

@media (min-width: 1050px) {
	.contact-page {
		padding: 180px 0 70px;
	}

	.featured-products {
		padding: 50px 0 110px;
	}

	/* revisar estee */
	.pagination-component {
		width: 250px;
	}
}

/*********** full screen loader ************/

 .fs-loader {
	 position: absolute;
	 top: 0;
	 left: 0;
	 right: 0;
	 bottom: 0;
	 height: 100%;
	 width: 100%;
	 z-index: 88;
	 background-color: rgba(255,255,255, .60);
	 display: none;
	 justify-content: center;
 }

 .fs-loader.visible {
	 display: flex;
 }

 .fs-loader .rotate {
	 font-size: 88px;
	 color: rgba(255,165,0,.6);
	 margin-top: 440px;
 }


/** **/